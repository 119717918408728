.navbar-brand {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.navbar-brand img {
    width: 116px;
    margin-right: 10px;
}

/* For larger screens */
.navbar-brand .brand-text {
    display: inline;
}

/* For smaller screens like phones */
@media (max-width: 768px) {
    .navbar-brand .brand-text {
        display: none;
    }
}

.custom-pr {
    padding-right: 0.75rem !important;
}

.custom-tns-nav {
    display: block !important;
}

.custom-tns-item {
    display: none;
}

/* Display the active image */
.custom-tns-item.active {
    display: block;
}